import request from 'superagent';
import { quotes } from '../constants/regExp';

const AUTHHEADER = 'Authorization';
const CONTENTTYPEHEADER = 'Content-Type';
const ACCEPTTYPE = 'application/json';

const CONTENTTYPE = {
  JSON: 'application/json;charset=utf-8',
  FORM: 'application/x-www-form-urlencoded',
  PLAIN: 'text/plain',
  BLOB: 'application/octet-stream',
  PDF: 'application/pdf',
  FILE: 'multipart/form-data',
};

const RequestWrap = (fn, type = '', data) => {
  const promise = new Promise((resolve, reject) => {
    if (type === CONTENTTYPE.FILE) {
      data.forEach((file) => {
        fn.attach('file', file);
      });
    }

    fn.end((err, response) => {
      if (err) {
        reject(response || err); // потому что response - это подробный объект с инфой об ошибке
      } else {
        if (type === CONTENTTYPE.BLOB) resolve(response.req.xhr.response);

        resolve(response.body);
      }
    });
  });

  promise.end = promise.then;
  promise.abort = fn.abort.bind(fn);

  return promise;
};

const getJSON = (token, url, params = null) => RequestWrap(request.get(url)
  .query(params)
  .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.JSON, [AUTHHEADER]: `Bearer ${token}` })
  .accept(ACCEPTTYPE)
);

const getFile = (token, url, params = null) => RequestWrap(request.get(url)
  .query(params)
  .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.JSON, [AUTHHEADER]: `Bearer ${token}` })
  .accept(ACCEPTTYPE)
  .responseType('blob'),
  CONTENTTYPE.BLOB
);

const getPDFFile = (token, url, params = null) => RequestWrap(request.get(url)
    .query(params)
    .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.PDF, [AUTHHEADER]: `Bearer ${token}` })
    .accept(CONTENTTYPE.PDF)
    .responseType('blob'),
  CONTENTTYPE.BLOB
);

const sendFile = (token, url, data, params = null) => RequestWrap(request.post(url)
  .query(params)
  .set({ [AUTHHEADER]: `Bearer ${token}` })
  .attach('file', data)
  .accept(ACCEPTTYPE)
);

const sendFiles = (token, url, data, params = null) => RequestWrap(request.post(url)
  .query(params)
  .set({ [AUTHHEADER]: `Bearer ${token}` })
  .accept(ACCEPTTYPE), CONTENTTYPE.FILE, data
);

const sendJSON = (token, url, data) => RequestWrap(request.post(url)
  .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.JSON, [AUTHHEADER]: `Bearer ${token}` })
  .accept(ACCEPTTYPE)
  .send(data)
);

const sendJSONPatch = (token, url, data) => RequestWrap(request.patch(url)
  .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.JSON, [AUTHHEADER]: `Bearer ${token}` })
  .accept(ACCEPTTYPE)
  .send(data)
);

const putJSON = (token, url, data) => RequestWrap(request.put(url)
  .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.JSON, [AUTHHEADER]: `Bearer ${token}` })
  .accept(ACCEPTTYPE)
  .send(data)
);

const delJSON = (token, url, data) => RequestWrap(request.del(url)
  .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.JSON, [AUTHHEADER]: `Bearer ${token}` })
  .accept(ACCEPTTYPE)
  .send(data)
);

const delDataJSON = (token, url, data) => RequestWrap(request.del(url)
  .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.JSON, [AUTHHEADER]: `Bearer ${token}` })
  .accept(ACCEPTTYPE)
  .send(data)
);

const sendJSONWithoutToken = (url, data) => RequestWrap(request.post(url)
  .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.JSON })
  .accept(ACCEPTTYPE)
  .send(data)
);

const download = url => window.open(url, 'Smartway - document download');

const downloadWithToken = (token, url) => new Promise((resolve, reject) => {
  getFile(token, url).then((blob) => {
    const downloadDiv = document.getElementById('downloadSector');
    const srcUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    const nameFromUrl = url.split('/')[4];

    downloadDiv.appendChild(a);
    a.href = srcUrl;
    a.download = `hotels-report-${nameFromUrl}.xls`;
    a.click();
    window.URL.revokeObjectURL(url);

    resolve();
  }, (err) => {
    reject(err);
  });
});

const downloadWithGuid = (token, url, downloadFileUrl) => new Promise((resolve, reject) => {
  getJSON(token, url).then((guid) => {
    const downloadDiv = document.getElementById('downloadSector');
    const preparedGuid = guid.replace(quotes, '');
    const srcUrl = `${downloadFileUrl}/${preparedGuid}`;
    const iframe = document.createElement('iframe');
    iframe.src = srcUrl;

    downloadDiv.innerHTML = '';
    downloadDiv.appendChild(iframe);

    resolve();
  }, (err) => {
    reject(err);
  });
});

const downloadWithGuidPOST = (token, url, downloadFileUrl, data) => new Promise((resolve, reject) => {
  sendJSON(token, url, data).then((guid) => {
    const downloadDiv = document.getElementById('downloadSector');
    const preparedGuid = guid.replace(quotes, '');
    const srcUrl = `${downloadFileUrl}/${preparedGuid}`;
    const iframe = document.createElement('iframe');
    iframe.src = srcUrl;

    downloadDiv.innerHTML = '';
    downloadDiv.appendChild(iframe);

    resolve();
  }, (err) => {
    reject(err);
  });
});

const downloadJSON = (url, guid) => {
  const downloadDiv = document.getElementById('downloadSector');
  const preparedGuid = guid.replace(quotes, '');
  const srcUrl = `${url}/${preparedGuid}`;
  const iframe = document.createElement('iframe');
  iframe.src = srcUrl;

  downloadDiv.innerHTML = '';
  downloadDiv.appendChild(iframe);
};

const downloadWithTokenZip = (token, url) => new Promise((resolve, reject) => {
  getFile(token, url).then((blob) => {
    const downloadDiv = document.getElementById('downloadSector');
    const srcUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    const nameFromUrl = url.split('/')[4];

    downloadDiv.appendChild(a);
    a.href = srcUrl;
    a.download = `${nameFromUrl}.zip`;
    a.click();
    window.URL.revokeObjectURL(url);

    resolve();
  }, (err) => {
    reject(err);
  });
});

const downloadWithTokenFile = (token, url, filename, extension) => new Promise((resolve, reject) => {
  getFile(token, url).then((blob) => {
    const downloadDiv = document.getElementById('downloadSector');
    const srcUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    downloadDiv.appendChild(a);
    a.href = srcUrl;
    a.download = `${filename}.${extension}`;
    a.click();
    window.URL.revokeObjectURL(url);

    resolve();
  }, (err) => {
    reject(err);
  });
});

export const postCredentials = (token, url) => RequestWrap(request.post(url)
  .withCredentials()
  .set({ [CONTENTTYPEHEADER]: CONTENTTYPE.JSON, [AUTHHEADER]: `Bearer ${token}` })
  .accept(ACCEPTTYPE));

const xhr = {
  getJSON,
  sendJSON,
  putJSON,
  delJSON,
  delDataJSON,
  downloadWithGuid,
  downloadWithGuidPOST,
  downloadWithTokenFile,
  sendFile,
  sendFiles,
  getPDFFile,
  sendJSONPatch,

  download,
  downloadWithToken,
  downloadWithTokenZip,
  sendJSONWithoutToken,
  downloadJSON,
};

export default xhr;
