const AGGREGATORS = {
  Petrolkard: 'Петролкард',
  ContourTravel: 'Контур Тревел',
  CKR: 'ЦКР',
  RusGidro: 'РусГидро',
};

const AGGREGATORS_NAME = {
  19202: AGGREGATORS.Petrolkard,
  20431: AGGREGATORS.ContourTravel,
  35748: AGGREGATORS.CKR,
  38980: AGGREGATORS.RusGidro,
};

export { AGGREGATORS_NAME, AGGREGATORS };
