const FLYCLASS = {
  Economy: 'Эконом',
  Comfort: 'Комфорт',
  Business: 'Бизнес',
  First: 'Первый',
};

const VALUETICKET = {
  INCLUDED: 'Included',
  CHARGE: 'Charge',
  NOTOFFERED: 'NotOffered',
  NOTDEFINED: 'NotDefined',
};

const REFUNDTICKET = {
  Included: 'без сбора',
  NotOffered: 'запрещен',
  Charge: 'со сбором',
};

const CHANGETICKET = {
  Included: 'бесплатно',
  NotOffered: 'запрещен',
  Charge: 'со сбором',
};

const CARRYON = {
  CARRYONFREE: 'Ручная кладь',
  CARRYONPAY: 'Ручная кладь: платно',
};

const BAGGAGE = {
  ONLYBAGGAGE: 'Багаж',
  INCLUDED: 'багаж включен',
  NOTOFFERED: 'багаж не включен',
};

const TARIFFS = {
  CHANGE: 'Изменения',
  TARIFFSONLY: 'Тариф',
  NOTOFFERED: 'невозвратный тариф',
  CHARGE: 'возврат до вылета со сбором',
  RETURNBEFOREDEPARTURE: 'Возврат до вылета',
  RETURNAFTEREDEPARTURE: 'Возврат после вылета: ',
  CHANGEBEFOREDEPARTURE: 'Изменения до вылета',
  CHANGEAFTERDEPARTURE: 'Изменения после вылета: ',
  INCLUDED: 'возврат до вылета без сборов',
  SPECIFY: 'Возврат: уточните возможность возврата',
};

const TRIP_MODIFICATIONS = [
  { name: 'Изменение времени вылета', type: 0 },
  { name: 'Изменение даты вылета', type: 1 },
  { name: 'Выбор места', type: 2 },
  { name: 'Дополнительный багаж', type: 3 },
  { name: 'Питание на рейс', type: 4 },
  { name: 'Оплата за животное', type: 5 },
  { name: 'Изменение аэропорта', type: 6 },
  { name: 'Изменение номера рейса', type: 7 },
  { name: 'Оплата за оружие', type: 8 },
  { name: 'Изменение класса обслуживания', type: 9 },
  { name: 'Смена терминала', type: 10 },
];

const AIRLINE_CLASSES_MAP = new Map([
  ['Econom', 'Эконом'],
  ['ECONOMY', 'Эконом'],
  ['Business', 'Бизнес'],
  ['BUSINESS', 'Бизнес'],
  ['First', 'Первый'],
  ['FIRST', 'Первый'],
]);

const AIRLINE_PROVIDERS = {
  S7: 'S7',
};

const VALUE_PROPERTY = {
  OFFICE_ID: 'OfficeId',
  SALE_POINT: 'SalePoint',
};

const AIRLINE_ITEMS = [
  { value: 'None', label: 'Не выбрано' },
  { value: 'Aeroflot', label: 'Аэрофлот (Aeroflot)' },
  { value: 'Amadeus', label: 'Амадеус (Amadeus)' },
  { value: 'Portbilet', label: 'Портбилет (Portbilet)' },
  { value: 'S7', label: 'S7 (S7)' },
  { value: 'Sirena', label: 'Сирена (Sirena)' },
  { value: 'aviacentr', label: 'Авиацентр (aviacentr)' },
  { value: 'Tais', label: 'ОРС (Tais)' },
  { value: 'Mixvel', label: 'Миксвел (Mixvel)' },
  { value: 'Azimut', label: 'Azimut' },
  { value: 'UzbekistanAirways', label: 'Uzbekistan Airways - прямой сток' },
  { value: 'ETS', label: 'ETS' },
];

const VALID_OPERATION_AIRLINES = [
  { IATA: 'U2' },
  { IATA: 'W6' },
  { IATA: 'EC' },
  { IATA: 'AI' },
  { IATA: 'FR' },
  { IATA: '6E' },
  { IATA: 'G6' },
  { IATA: 'G9' },
  { IATA: 'D8' },
];

export {
  FLYCLASS as default,
  VALUETICKET,
  REFUNDTICKET,
  CHANGETICKET,
  BAGGAGE,
  TARIFFS,
  AIRLINE_CLASSES_MAP,
  CARRYON,
  AIRLINE_PROVIDERS,
  AIRLINE_ITEMS,
  TRIP_MODIFICATIONS,
  VALID_OPERATION_AIRLINES,
  VALUE_PROPERTY,
};
